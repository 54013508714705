import tw from 'tailwind.macro';
import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import SEO from '../components/seo';
import Footer from '../components/Footer';
import ContactSection from '../components/ContactSection';
import Nav from '../components/Nav';
import contentOne from '../images/Image-4.png';
import imageTileOne from '../images/image-110.png';
import partnerAsiaInnovations from '../images/asiainnovations.svg';
import partner8vc from '../images/8vc.svg';
import partnerCrowdbotics from '../images/crowdbotics.svg';
import partnerShopify from '../images/shopify.svg';
import partnerGoat from '../images/goat.svg';
import partnerC19 from '../images/c19.svg';
import partnerHelena from '../images/helena.svg';
import serviceAP from '../images/AP logo copy.png';
import serviceRadi2 from '../images/RADII.png';
import serviceSVB from '../images/SVB.png';
import serviceLathamWatkins from '../images/Latham_&_Watkins_Logo.png';
import serviceFlexport from '../images/flexport-blue.png';
import customerHawaii from '../images/hawaii.svg';
import customerIllinois from '../images/illinois.svg';
import customerNY from '../images/newyork.svg';
import customerDetroit from '../images/detroit.svg';
import customerCedars from '../images/cedars.svg';
import customerFord from '../images/ford.svg';
import customerSuny from '../images/suny.svg';
import customerBeaumont from '../images/beaumont.svg';
import publicationNyt from '../images/nyt-logo.svg';
import publicationWsj from '../images/wsj.svg';
import publicationFt from '../images/ft.svg';
import publicationBloomberg from '../images/bloomberg.svg';
import publicationMit from '../images/mit.svg';
import publicationWired from '../images/wired.svg';
import wavyLines from '../images/wavy-lines.png';
import contentTwoChild from '../images/child.svg';
import Echo from '../images/echo-blue.png';
import KirklandEllis from '../images/kirklandEllis.png';
import Deliverr from '../images/deliverr.png';
import partnerHumanity from '../images/humanity-forward.svg';
import heroImage from '../images/doctor-om-bag.png';
import rectangles from '../images/rectangles.svg';
import rectanglesRight from '../images/rectangles-right.svg';
import Fade from 'react-reveal/Fade';

const Wrapper = tw.div`
  flex items-center flex-col 
`;

const Logo = tw.div`
  flex items-center flex-shrink-0 mr-6
`;

const LogoText = tw.span`
font-semibold text-xl tracking-tight
`;

const CTAButton = tw.a`
bg-themeGreen-500 border-2 border-themeGreen-500 text-sm px-6 py-3 leading-none text-white mt-4 mt-0 inline-block text-center
`;

const CTAButtonSecondary = tw.a`
border-2 border-themeGreen-300 text-themeGreen-300 text-sm px-6 py-3 leading-none mt-4 mt-0 bg-white inline-block text-center
`;

// Hero Section and Typography
// =====================================
const HeroText = styled('h1')`
  ${tw`sm:text-3xl text-themeGreen-700`};

  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 123.19%;
`;

const HeroSubText = styled('h3')`
  ${tw` text-lg w-5/6 sm:w-full pt-2 pb-4 leading-loose`};
`;

const HeroSubTextNote = styled('h3')`
  ${tw` text-sm w-11/12 sm:w-full leading-loose italic text-themeGreen-700`};
`;

const HeroSection = styled('div')`
  ${tw`w-full bg-no-repeat bg-top sm:px-6 md:px-4 overflow-hidden mb-4`}
  background-image url(${wavyLines});
  background-size: contain;
  
  @media (min-width: 1024px) {
    min-height: 700px;
  }
`;

const HeroSectionContentContainer = tw.div`
flex sm:flex-column md:flex-row w-full max-w-6xl md:pt-16 sm:flex-col relative h-full m-auto
`;

const HeroSectionLeft = tw.div`
md:w-3/5 sm:flex sm:flex-col
`;

const HeroSectionRight = tw.div`
md:w-2/5
`;
const HeroButtonContainer = tw.div`
  flex sm:w-full items-center
`;

const MessageSection = tw.div`
  flex flex-row w-full font-medium justify-center mt-4 sm:px-6 relative
`;

const GraphMessageSection = styled('div')`
  ${tw`absolute sm:hidden `}
  left: 3%;
  top: 2%;
  transform: rotate(-90deg);
`;

const GraphTestimonial = styled('div')`
  ${tw`absolute sm:hidden `}
  right: 0;
  top: -7.125rem;
  img {
    max-width: 255px;
    max-height: 235px;
  }
`;

const MessageText = styled('h1')`
  ${tw`text-2xl text-themeGreen-500 text-center mt-32 mb-20 leading-loose font-normal`}
  z-index: 1;
  background-color: #fff;
  max-width: 822px;
`;

const PublicationContainer = styled('div')`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  column-gap: 0.5rem;
  row-gap: 1rem;
  @media (min-width: 800px) {
    grid-template-columns: repeat(3, 1fr);
  }
  max-width: 36.25rem;
`;

const Publication = styled('a')`
  ${props => (!!props.start ? tw`justify-start` : tw`justify-center`)};
  ${tw`sm:justify-start`}
  display: flex;
  align-items: center;
  img {
    position: ${props => (props.leftAlignment ? 'absolute' : 'unset')};
  }
`;

const PublicationSubtext = styled('div')`
  ${tw`pt-10 pb-5 text-sm text-themeGray-500 lg:mt-20`}
  opacity: 0.45;
`;

const HeroImg = styled('img')`
  ${tw`lg:absolute`}
  top: 0;
`;

// Content Section
// =====================================
const SectionInner = tw.div`
 flex flex-row max-w-6xl sm:flex-col
`;

const SectionHeading = tw.h2`
  text-3xl
`;

const ContentText = styled('h3')`
  ${tw`text-sm w-5/6 sm:w-full pt-2 pb-4 leading-loose text-themeGray-300`};
  z-index: 1;
  background-color: #fff;
`;

const ContentSection = tw.div`
flex flex-row w-full bg-white font-medium justify-center pt-20 sm:pt-12 relative sm:px-6 md:px-4
`;

const ContentSectionTitleTestimonials = tw.div`
  flex flex-col w-full bg-white font-medium justify-center pt-20 relative sm:px-6 md:px-4
`;

const ContentSectionLeft = tw.div`
flex flex-col lg:w-1/2 bg-white font-medium justify-center sm:items-center lg:pr-12
`;

const ContentSectionRight = tw.div`
flex flex-col lg:w-1/2 bg-white font-medium justify-center sm:items-center lg:pl-12 relative
`;

const TestimonialContentSectionRight = tw.div`
  flex flex-col bg-white  relative md:pl-8 lg:pl-12 lg:w-1/2 sm:my-8 sm:items-center
`;

const TestimonialContent = styled('div')`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 3rem;
  column-gap: 3rem;
  max-width: 66.875rem;

  @media (min-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
  }

`;

const ImageTile = styled('img')`
  border-radius: 0.3125rem;
  ${props => (!!props.marginTop ? tw`mt-10` : tw``)};
`;
const ImageTestimonial_1 = styled('img')`
  ${tw`self-start sm:self-center`};
  border-radius: 0.3125rem;
  width: auto;
  height: auto;
  max-width: 17.9106rem;
  max-height: 15rem;
`;
const ImageTestimonial_2 = styled('img')`
  ${tw`mt-8 self-end sm:self-center`};
  border-radius: 0.3125rem;
  width: auto;
  height: auto;
  max-width: 23.8269rem;
  max-height: 15rem;
`;

const ContentTwoDoctor = styled('img')`
  border-radius: 0.3125rem;
`;

const ContentTwoChild = styled('img')`
  border-radius: 0.3125rem;
`;

const TestimonialContainer = styled('div')`
  display: flex;
`;

const TestimonialLogo = styled('img')`
  ${tw`self-start`};
`;

const TestimonialText = styled('div')`
  ${tw`leading-loose font-normal italic text-themeGray-300`};
  font-size: 0.75rem;
`;

const TestimonialByText = styled('div')`
  ${tw`pb-4 leading-loose font-normal text-themeOrange-500 font-bold`};
  font-size: 0.75rem;
`;

// Partner Section
// =====================================
const PartnerSection = tw.div`
  flex flex-col w-5/6 bg-white font-medium items-center lg:py-16 py-10
  sm:w-full sm:px-6 md:px-4
`;

const PartnerSectionTitle = tw.h3`
  text-black mb-16 font-bold text-3xl sm:mt-10
`;

const PartnerSectionImg = styled('img')`
  opacity: 1;
`;

const PartnerSectionTileWrapper = styled('div')`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const PartnerSectionTile = styled('div')``;

const PartnerSectionGrid = styled('div')`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 0.625rem;
  column-gap: 0.625rem;
  margin-bottom: 1.25rem;

  @media (min-width: 800px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

// Service Section
// =====================================
const ServiceSection = tw.div`
  flex flex-col w-5/6 bg-white font-medium items-center 
  lg:pt-10 lg:pb-24 lg:px-20 sm:pb-6
`;

const ServiceSectionTitle = tw.h3`
  text-black mb-16 font-bold text-3xl sm:mt-10
`;

const ServiceSectionGrid = styled('div')`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  column-gap: 5rem;
  row-gap: 3rem;
  align-items: center;
  justify-items: center;
  max-width: 50rem;

  @media (max-width: 1024px) {
    margin-bottom: 2.5rem;
  }
  @media (max-width: 800px) {
    column-gap: 1rem;
  }
`;

const ServiceSectionImg = styled('img')`
  ${props => (!!props.limitWidth ? tw`sm:w-3/4` : tw``)};
  max-width: ${props => (props.limitWidth ? '6.25rem' : '100%')};
  mix-blend-mode: luminosity;
  opacity: 0.9;
  max-height: 110px;
`;

// Customer Section
// =====================================

const CustomerSectionTitle = tw.span`
  text-themeGreen-700 mb-12 text-lg text-center
`;

const CustomerSectionGrid = styled('div')`
  max-width: 50rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 4rem;
  row-gap: 4rem;
  align-items: center;
  justify-items: center;
  @media (min-width: 800px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const CustomerSectionTile = styled('img')`
  opacity: 0.9;
  max-height: 60px;
  width: 100%;
`;

const SectionContentInner = tw.div`
  px-6
`;

const CustomerSection = styled('div')`
  ${tw`w-full py-12`}
  background-color: #F6F6F6;
`;

const CustomerSectionContent = tw.div`
  flex flex-col justify-center items-center
`;

export default () => (
  <Wrapper>
    <SEO title="Home" />
    <Nav />
    <HeroSection>
      <HeroSectionContentContainer>
        <HeroSectionLeft>
          <HeroText>From Factory to Frontline</HeroText>
          <HeroSubText>Fulfilling a relief mission at the speed of a startup</HeroSubText>
          <HeroButtonContainer>
            <CTAButton css={tw`md:mr-16 sm:mr-6 py-4 px-8`} href="https://shop.operationmasks.org">
              Buy PPE Now
            </CTAButton>
            <CTAButtonSecondary
              css={tw`mr-4 py-4 px-8 font-bold`}
              href="https://secure.squarespace.com/checkout/donate?donatePageId=5e7b676d53c38d47a7c9ed70&ss_cid=a8e2e880-e7af-4fe4-8a2b-9670ddcfb885&ss_cvisit=1589591662979&ss_cvr=8c372247-4a02-4646-a8a0-0010701b9c9f%7C1587414536379%7C1589306236254%7C1589591662531%7C20"
              target="_blank"
            >
              Donate
            </CTAButtonSecondary>
          </HeroButtonContainer>

          <PublicationSubtext>Where people have talked about us:</PublicationSubtext>
          <PublicationContainer>
            <Publication
              start
              target="_blank"
              href="https://www.nytimes.com/2020/04/03/technology/coronavirus-masks-shortage.html"
            >
              <img src={publicationNyt} />
            </Publication>
            <Publication
              target="_blank"
              href="https://www.wsj.com/articles/two-best-friends-and-the-global-treasure-hunt-for-coronavirus-supplies-11585410259"
            >
              <img src={publicationWsj} />
            </Publication>
            <Publication
              target="_blank"
              href="https://www.bloomberg.com/news/audio/2020-04-06/lonsdale-says-tech-is-stepping-up-in-pandemic-podcast?srnd=businessweek-v2"
            >
              <img src={publicationBloomberg} />
            </Publication>
            <Publication
              start
              target="_blank"
              href="https://www-technologyreview-com.cdn.ampproject.org/c/s/www.technologyreview.com/2020/04/27/1000457/ppe-masks-huawei-alibaba-tencent-china-diplomacy/amp/"
            >
              <img src={publicationMit} />
            </Publication>
            <Publication
              target="_blank"
              href="https://www.wired.com/story/linkedin-coronavirus-medical-equipment-ppe-shortage/"
            >
              <img src={publicationWired} />
            </Publication>
            <Publication target="_blank" href="https://www.ft.com/content/22430f34-cb50-4f43-80eb-e2a28b88078e">
              <img src={publicationFt} />
            </Publication>
          </PublicationContainer>
        </HeroSectionLeft>
        <HeroSectionRight>
          <HeroImg src={heroImage} />
        </HeroSectionRight>
      </HeroSectionContentContainer>
    </HeroSection>

    <CustomerSection>
      <SectionContentInner>
        <CustomerSectionContent>
          <CustomerSectionTitle css={tw`font-normal`}>Some of our PPE Recipients:</CustomerSectionTitle>
          <CustomerSectionGrid>
            <Fade cascade>
              <CustomerSectionTile src={customerHawaii} />
              <CustomerSectionTile src={customerIllinois} />
              <CustomerSectionTile src={customerNY} />
              <CustomerSectionTile src={customerDetroit} />
              <CustomerSectionTile src={customerCedars} />
              <CustomerSectionTile src={customerFord} />
              <CustomerSectionTile src={customerSuny} />
              <CustomerSectionTile src={customerBeaumont} />
            </Fade>
          </CustomerSectionGrid>
        </CustomerSectionContent>
      </SectionContentInner>
    </CustomerSection>

    <MessageSection>
      <GraphMessageSection>
        <img src={rectangles} />
      </GraphMessageSection>
      <MessageText>
        Operation Masks is a nonprofit created by a group of entrepreneurs in healthcare, technology, and logistics who
        want to contribute to the fight against COVID-19
      </MessageText>
    </MessageSection>
    <ContentSection>
      <SectionInner>
        <ContentSectionLeft css={tw`lg:pr-0`}>
          <Fade><img src={contentOne} /></Fade>
        </ContentSectionLeft>
        <ContentSectionRight css={tw`lg:pl-16 justify-start lg:pt-12`}>
          <SectionHeading>Supporting Our Healthcare Heroes</SectionHeading>
          <ContentText>
            The COVID-19 crisis has caused an unprecedented strain on the complex global supply chain upon which
            healthcare providers everywhere rely. Today in the United States, millions of men and women serving on the
            frontlines of the crisis face a dire shortage of critical equipment.
          </ContentText>
          <ContentText>
            Not only does this endanger them and their families, it also hinders their ability to effectively provide
            care for us all. We decided to help get them the protective gear they need as they fight this pandemic.
          </ContentText>
        </ContentSectionRight>
      </SectionInner>
    </ContentSection>
    <ContentSection>
      <SectionInner>
        <ContentSectionLeft>
          <SectionHeading>Protective equipment shortage in the U.S.</SectionHeading>
          <ContentText css={tw`w-11/12`}>
            The lack of personal protective equipment (PPE) at U.S. hospitals is critical. We have set up the
            relationships to get PPE such as masks and gloves from payment to factory procurement to shipping to ground
            delivery to hospitals, with the help of our partners at Flexport, Deliverr, and Echo.
          </ContentText>
          <ContentText css={tw`w-11/12`}>
            If you are a hospital or provider in need, please sign up for supplies here. And if you are willing to
            donate, every dollar donated goes towards masks for doctors, nurses, and other emergency workers.
          </ContentText>
          <HeroSubTextNote css={tw`text-themeGreen-500`}>
            Note: Operation Masks is currently only serving federal agencies, state governments, and hospitals in the
            United States
          </HeroSubTextNote>
          <CTAButton css={tw`mr-4 mt-8 py-4 px-8 w-48`} href="https://shop.operationmasks.org">
            Buy PPE Now
          </CTAButton>
        </ContentSectionLeft>
        <ContentSectionRight css={tw`mt-10`}>
          <Fade css={css`position: absolute; top: 0px;`}><ContentTwoChild src={contentTwoChild} /></Fade>
        </ContentSectionRight>
      </SectionInner>
    </ContentSection>
    <ContentSection>
      <SectionInner>
        <ContentSectionLeft css={tw`lg:pr-0 lg:justify-start md:w-1/2 md:pr-4`}>
          <Fade><ImageTile css={tw`sm:pb-10`} src={imageTileOne} /></Fade>
        </ContentSectionLeft>
        <ContentSectionRight css={tw`pl-0 lg:justify-start lg:mt-10 md:w-1/2`}>
          <SectionHeading> We strictly monitor quality directly with manufacturers </SectionHeading>
          <ContentText css={tw`w-11/12`}>
            Operation Masks has boots on the ground at the source of manufacturing. We quality check each supplier to
            ensure PPE buyers are protected from the rampant fraud that is prevelant around the world. Integrity is
            fundamental to our culture and the way we run our organization.
          </ContentText>
        </ContentSectionRight>
      </SectionInner>
    </ContentSection>

    <ContentSection>
      <GraphTestimonial>
        <img src={rectanglesRight} />
      </GraphTestimonial>
      <SectionInner css={tw`flex-col`}>
        <SectionHeading css={tw`text-center`}>Testimonials </SectionHeading>
        <HeroSubText css={tw`text-center w-full text-sm font-normal text-themeGray-300`}>
          {' '}
          Words that keep us inspired
        </HeroSubText>

        <TestimonialContent css={tw`pr-0 justify-start`}>
          <TestimonialContainer>
            <div>
              <TestimonialText>
                "Cities like New York, with their budget and size can place a million-dollar order without much worry.
                Here in Hawaii, we simply don't have that buying power, and we would be devastated if the masks turned
                out to be counterfeit or the deal fell through. That's where Operation Masks came in for us. Robin and
                his team were able to use their connections and leverage to not only secure a deal for us, but
                personally verify the quality and set favorable terms for us. We are so unbelievably relieved to have
                their support."
              </TestimonialText>
              <TestimonialByText>- Cathy Ross, Department of Health, State of Hawaii</TestimonialByText>
            </div>
          </TestimonialContainer>

          <TestimonialContainer>
            <div>
              <TestimonialText>
                “We are so grateful to Operation Masks for making these allocations available to smaller organizations
                like ours and for all the incredible work their team is doing to secure this much needed PPE for
                healthcare providers.”
              </TestimonialText>
              <TestimonialByText>- Lisa Norris, WelbeHealth, Los Angeles</TestimonialByText>
            </div>
          </TestimonialContainer>

          <TestimonialContainer>
            <div>
              <TestimonialText>
                “Thank you for the excellent quality face shields. As a small family office we don't have the same
                access to PPE that every healthcare worker needs and we're so grateful that Operation Masks is making
                that available to us so quickly.”
              </TestimonialText>
              <TestimonialByText>- Dr. Peter Lok, Lok Acupuncture Clinic, Las Vegas</TestimonialByText>
            </div>
          </TestimonialContainer>

          <TestimonialContainer>
            <div>
              <TestimonialText>
                “We are not normally in the need to purchase PPE. However, since facilities we normally work with are
                short in these areas, we have been searching so that our Anesthesiologist and CRNAs are covered. This is
                a HUGE help!”
              </TestimonialText>
              <TestimonialByText>- Kim Lesh, Director of Risk and Quality, North Star Anesthesia</TestimonialByText>
            </div>
          </TestimonialContainer>
        </TestimonialContent>
      </SectionInner>
    </ContentSection>

    <PartnerSection>
      <PartnerSectionGrid>
        <Fade cascade>
          <PartnerSectionTile>
            <PartnerSectionImg src={partnerGoat} />
          </PartnerSectionTile>
          <PartnerSectionTile>
            <PartnerSectionImg src={partnerCrowdbotics} />
          </PartnerSectionTile>
          <PartnerSectionTile>
            <PartnerSectionImg src={partnerAsiaInnovations} />
          </PartnerSectionTile>
          <PartnerSectionTile>
            <PartnerSectionImg src={partner8vc} />
          </PartnerSectionTile>
          <PartnerSectionTile>
            <PartnerSectionImg src={partnerHumanity} />
          </PartnerSectionTile>
          <PartnerSectionTile>
            <PartnerSectionImg src={partnerShopify} />
          </PartnerSectionTile>
          <PartnerSectionTile>
            <PartnerSectionImg src={partnerC19} />
          </PartnerSectionTile>
          <PartnerSectionTile>
            <PartnerSectionImg src={partnerHelena} />
          </PartnerSectionTile>
        </Fade>
      </PartnerSectionGrid>
    </PartnerSection>

    <ServiceSection>
      <ServiceSectionTitle>Services</ServiceSectionTitle>
        <ServiceSectionGrid>
          <Fade cascade>
            <ServiceSectionImg src={serviceFlexport} />
            <ServiceSectionImg src={Echo} />
            <ServiceSectionImg src={Deliverr} />
            <ServiceSectionImg limitWidth src={serviceSVB} />
            <ServiceSectionImg src={KirklandEllis} />
            <ServiceSectionImg src={serviceLathamWatkins} />
            <ServiceSectionImg src={serviceAP} />
            <ServiceSectionImg src={serviceRadi2} />
          </Fade>
        </ServiceSectionGrid>
    </ServiceSection>

    <ContactSection />
    <Footer />
  </Wrapper>
);
